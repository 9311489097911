<template>
  <b-card title="Data Calon Siswa">
    <div class="custom-search">
      <!-- advance search input -->
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>Cari:</label>
            <b-form-input
              placeholder="Cari"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>        
      </b-row>
    </div>       
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
// import Table from '@/views/table/vue-good-table/GoodTable.vue'
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import { codeAdvance } from './code'

export default {
  components: {
    // BCardCode,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    // BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      codeAdvance,
      columns: [       
        {
          label: 'Judul',
          field: 'title',
        },
        {
          label: 'Icon',
          field: 'icon',
        },
        {
          label: 'Route',
          field: 'route',
        },
        {
          label: 'Header menu',
          field: 'ishead',
        },
        {
          label: 'ID Header menu',
          field: 'idhead',
        },
        {
          label: 'Aktif',
          field: 'active',
        },
        {
          label: 'Notes',
          field: 'notes',
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
        },
      ],
      rows: [],
      searchTerm: '',
      modalRemove: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    //this.getAllMenu()
  },
  methods: {
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Employee.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddMenu() {
      this.$router.push({ name: 'menu-data-add' })
    },
    toRouteEditMenu(val) {
      this.$router.push({ name: 'menu-data-edit', params: { id: val } })
    },
    toRouteDetailMenu(val) {
      this.$router.push({ name: 'menu-data-detail', params: { id: val } })
    },
    async getAllMenu() {
      try {
        const response = await this.$http.get('/menus')
        this.rows = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Menu Not Success')
        console.log(err)
      }
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeParent(val) {
      try {
        console.log(val)
        await this.$http.delete(`/menus/${val}`)
        await this.getAllMenu()
        this.showToast('success', 'top-center', 'Delete Menu Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Menu Not Success')
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
